import $ from 'jquery';

$(document).ready(function() {

 $(".custom-file-input-pro").change(function () {
    $(this).parent().next(".custom-file-label").text(this.files[0].name);

  });


  $('.rech-link').click(function(e){
    e.preventDefault();
    $('.center-search').toggleClass('search-open');

  });
  $('.close-search').click(function(e){
    e.preventDefault();
    $('.center-search').removeClass('search-open');

  });
  $(".link-top-language").click(function(e){
		e.preventDefault();
		$('.langue-list').toggleClass('focus');
	});

  $('body').on('click', '.link-top-language', function (e) {
    e.stopPropagation();
    return false;
  });
  $('body').on('click', function () {
    $('.link-top-language').removeClass('active');
    $('.langue-list').removeClass('focus');
  });

  /*********PANIER DEROULANt*****************/
  $('.hover-cart-popup').hover(function () {
		$(".panier-deroulant").toggleClass('focus');
    $(".header-body").toggleClass('no_zindex');
	});


  /*** Header sticky **/
  $(function () {
    var msie6 = $.browser == 'msie' && $.browser.version < 7;

    if (!msie6) {
      var top = $('.header-body').offset().top - parseFloat($('.header-body').css('margin-top').replace(/auto/, 0));
      $(window).scroll(function (event) {
        // what the y position of the scroll is
        var y = $(this).scrollTop();

        // whether that's below the form
        if (y > top) {
          // if so, ad the fixed class
          $('.header-body').addClass('headerFixed');
          $('.big-wrapper').addClass('padding-body');
        } else {
          // otherwise remove it
          $('.header-body').removeClass('headerFixed');
          $('.big-wrapper').removeClass('padding-body');
        }
      });
    }
  });




  /********SUB MENU OVERLAY******/
  if ($(window).width() > 992) {

    var navOverlay_ = $('.menu-overlay');
    $('.navItem').hover(function () {
      var $this = $(this);
      if ($this.find('.subMenu').length) {
        $this.find('.navLink').addClass('active');
        $('body').addClass('hover-item');

      }
    }, function () {
      if ($(this).find('.subMenu').length) {
        $(this).find('.navLink').removeClass('active');
        $('body').removeClass('hover-item');

      }
    });
  }
  else
  {
    $(".navItem").each(function() {
      if ($(this).children('.navLink').next('.subMenu').length==1){
        $(this).addClass('has-sub');
        $(this).children(".navLink").click(function(e){
          var parents_nav = $(this).parents('.onglets');
          var thisAnchor2 = $(this);
          e.preventDefault();
          if (thisAnchor2.next('.subMenu').css('display') !== 'none'){
            thisAnchor2.parent('.navItem').removeClass('active');
            thisAnchor2.next('.subMenu').slideUp();

          }
          else{
            parents_nav.find('.subMenu').slideUp();
            parents_nav.find('.navItem').removeClass('active');
            thisAnchor2.siblings('.subMenu').slideDown();
            thisAnchor2.addClass('active');
          }
        });
      }
    });
  }


$('.open-cookies').click(function(e){
  e.preventDefault();
  $('.ch-cookie-consent__category-group').fadeIn();
  $('.loading-overlay-c').fadeIn();

});
$('.close-cookies').click(function(e){
  e.preventDefault();
  $('.ch-cookie-consent__category-group').fadeOut();
  $('.loading-overlay-c').fadeOut();

});
$('.ch-cookie-consent__btn').click(function(){
  $('#modalCookies2').fadeOut();
});




  /** Scroll top Top **/
  $(window).scroll(function () {
    if ($(this).scrollTop() > 600) {
      $('.goToTop').fadeIn();
    } else {
      $('.goToTop').fadeOut();
    }
  });
  $('.goToTop').click(function () {
    $("html, body").animate({
      scrollTop: 0
    }, 600);
    return false;
  });

  /******* MENU TOOGLE *********/

  $('.toggle-menu').click(function(e){
    e.preventDefault();
    $('.navigation-site').toggleClass('open-mob');
    $('.menu-mob-overlay').fadeToggle();
    $('body').toggleClass('no-scrolling');
  });
  $('.close-menu-mob').click(function(e){
    e.preventDefault();
    $('.navigation-site').removeClass('open-mob');
    $('.menu-mob-overlay').fadeOut();
    $('body').toggleClass('no-scrolling');
  });
  $('.menu-mob-overlay').click(function(e){
    e.preventDefault();
    $('.navigation-site').removeClass('open-mob');
    $('.menu-mob-overlay').fadeOut();
    $('body').toggleClass('no-scrolling');
  });

  $('.link-account-mobile').click(function(e){
    e.preventDefault();
    $('.content-user-account').toggleClass('show-account');


  });
  /********MENU FOOTER TOGGLE********/

  $('.toogle-menu-mob').click(function(){
    $('.other-menu').slideToggle();
  });

  $('.toggle-me').click(function(){
    var thisAnchor = $(this);
    var tab = thisAnchor.parents('.bloc-footer-menu');
    if(thisAnchor.next('.toggle-m-c ').css('display') !== 'none'){
      thisAnchor.removeClass('open');
      thisAnchor.next('.toggle-m-c').slideUp();
    }
    else{
      tab.find('.toggle-m-c').slideUp();
      tab.find('.toggle-me').removeClass('open');
      thisAnchor.siblings('.toggle-m-c ').slideDown();
      thisAnchor.addClass('open');}
  });

  $('.card-header').click(function(){
    var thisA = $(this);
    var tabA = thisA.parents('#accordion');
    if(thisA.next('.collapse').css('display') !== 'none'){
      thisA.removeClass('open');
      thisA.next('.collapse').slideUp();
    }
    else{
      tabA.find('.collapse').slideUp();
      tabA.find('.card-header').removeClass('open');
      thisA.siblings('.collapse').slideDown();
      thisA.addClass('open');}
  });

	/***WOW****/
	var wow = new WOW(
    {
      boxClass:     'wow',
      animateClass: 'animated',
      offset:       0,
      mobile:       false,
      live:         true,
      callback:     function(box) {
      },
      scrollContainer: null
      }
    );
    if($(window).width()>1024){
      wow.init();
    }

  /*
    Carousel
*/

  parallaxIt();

});
// makes the parallax elements
function parallaxIt() {
  // create variables
  var $fwindow = $(window);
  var scrollTop = window.pageYOffset || document.documentElement.scrollTop; // on window scroll event

  $fwindow.on('scroll resize', function () {
    scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  }); // for each of content parallax element

  $('[data-type="content"]').each(function (index, e) {
    var $contentObj = $(this);
    var fgOffset = parseInt($contentObj.offset().top + 50);
    var yPos;
    var speed = $contentObj.data('speed') || 1;
    $fwindow.on('scroll resize', function () {
      yPos = fgOffset - scrollTop / speed;
      $contentObj.css('bottom', yPos);
    });
  }); // for each of background parallax element

  $('[data-type="background"]').each(function () {
    var $backgroundObj = $(this);
    var bgOffset = parseInt($backgroundObj.offset().top + parseInt($('footer').height()));
    var yPos;
    var coords;
    var speed = $backgroundObj.data('speed') || 1;
    $fwindow.on('scroll resize', function () {
      yPos = -((bgOffset - scrollTop) / speed);
      coords = '50% ' + yPos + 'px';
      $backgroundObj.css({
        backgroundPosition: coords
      });
    });
  }); // triggers winodw scroll for refresh

  $fwindow.trigger('scroll');
};
function init_slide(){
    /* Home slider */
	/********HOME SLIDER********/
	var swiperhome = new Swiper('.slider-home', {
		pagination: '.swiper-pagination',
		nextButton: '.home-next',
		prevButton: '.home-prev',
    paginationClickable: true,
    slidesPerView: 1,
    spaceBetween: 30,
    loop: true,
		autoplay: 2500,
		speed: 900,
    effect: 'fade'
    });
	/* cpte slide */

  var nbre_pahg= $('.slider-home').children('.swiper-pagination').find('.swiper-pagination-bullet').length;
	if(nbre_pahg<=1){
		$('.slider-home').addClass('just-one')
	}
	else{
			$('.slider-home').removeClass('just-one')
	}

  $(".slider-home").hover(function(){
    swiperhome.stopAutoplay();
  }, function(){
    swiperhome.startAutoplay();
});






}
window.addEventListener("resize", function(){
  init_slide();
});
$(document).ready(function(e) {
  init_slide();
/* LIGHTBOX*/




//user dashboard
  $("body").on("click", ".relation-request", function (event) {

    var jobId = this.id;
    var projectId = $(this).attr('data-project-id');
    var btn = $(this);
    $.ajax({
        url: '/relationship/'+projectId+"/"+jobId,
        type: "GET",
        success: function (data) {
           btn.closest('div').html(data)
        },
        // La fonction à appeler si la requête n'a pas abouti
        error: function () {
            console.log("error");
        }
    });
  });

});






